.offers__container {
  background-color: #b10000;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.offer__title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #ffffff;
  text-align: center;
  padding: 20px;
}

.offer__content {
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 1.6;
  color: #ffffff;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  margin: 2px;
}
@keyframes slideInOffer {
  0% {
    opacity: 0;
    transform: translateX(-550px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated {
  animation: slideInOffer 2.5s ease-in-out forwards;
}
.glitter {
  position: relative;
  animation: glitter 2s infinite linear;
}

@keyframes glitter {
  0% {
    color: white;
    text-shadow: none;
  }
  50% {
    color: #ffd700; /* Glitter color (gold) */
    text-shadow: 0 0 10px #ffd700, 0 0 20px #ffd700, 0 0 30px #ffd700;
  }
  100% {
    color: white;
    text-shadow: none;
  }
}
.offer__content::after {
  content: ""; /* Create a pseudo-element for the sparkling effect */
  position: absolute; /* Position the pseudo-element */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: sparkle 2s infinite; /* Apply the sparkle animation */
}

@keyframes sparkle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    color: #ffd700; /* Glitter color (gold) */
    text-shadow: 0 0 10px #ffd700, 0 0 20px #ffd700, 0 0 30px #ffd700; /* Add text shadow for sparkling effect */
  }
  100% {
    opacity: 0;
  }
}
