.table__cell__width:nth-child(-n+5) {
    min-width: 250px;
    /* Adjust the width as needed */
    width: 250px;
    max-width: 250px;

}

/* Set the last cell to take up the remaining space */

.table-header-cell {
    background-color: #f8f9fa;
    font-weight: bold;
}
/* Override the box shadow for TableContainer */
.MuiPaper-root.MuiTableContainer-root {
    box-shadow: none;
}



@media screen and (max-width: 768px) {
    .table__cell__width {
        min-width: 50px;
        width: 50px;
        max-width: 50px;
    }
}