newsWrapper {
  display: flex;
}
.news-section {
  /* Add your styles here */
}

/* Style for news section title */
.news-section-title {
  /* Add your styles here */
}
