/*
.tabbked__section {
  background-color: var(--padding-big);
}
.tabbed__section__title {
  margin-left: 10px !important;
  margin-bottom: 10px !important;
}
.tabs {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 25px;
  background-color: var(--site-color);
  border: 1px solid #d3d3d3;
  padding: var(--padding-big);
  box-shadow: 1px 1px 1px #d3d3d3;
  justify-content: space-evenly;
  height: 500px;
  width: auto;
}
.tabs li {
  margin: 1px;
  text-align: center;

  color: white;
  cursor: pointer;
  flex-basis: 100%;
  box-shadow: 1px 1px 1px 1px #eee;
}
.tabs li.active {
  text-align: center;

  color: white;
  cursor: pointer;
  flex-basis: 100%;
  box-shadow: 2px 2px 2px 2px #eee;
  border: 1px solid black;
  border-radius: 2px;
}
.max-w {
  background-color: var(--site-color);
  width: 50%;
  margin-left: auto;
  box-shadow: 1px 1px 1px 1px #d3d3d3;
  border: 1px solid #d3d3d3;
  padding: 20px;
  border: 1px solid #d3d3d3;
  margin-bottom: auto;
  border-radius: 25px;
}*/
/* new tabbed*/
.tabbed__section__title {
  padding-top: 20px !important;
  margin-left: 15px !important;
  margin-bottom: 10px !important;
}
.tabs {
  padding: 10px;
}
.tab {
  margin-left: 10px;
  height: 100vh;
  overflow: hidden;
}
.tab__info {
  height: 200px;
  max-width: 80%;
}
.tab__image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px gainsboro;
  border: 1px solid black;
}
@media (max-width: 768px) {
  .tab__image {
    width: 80%; /* Scale down to 80% width */
    height: auto; /* Let height adjust automatically to maintain aspect ratio */
  }
}