.price-header-icon {
  display: flex;
  align-items: center;
  padding: var(--padding-medium) 0 var(--padding-medium) var(--padding-small);
  margin: var(--padding-small) 0 var(--padding-medium) var(--padding-small);
}
.table-responsive {
  overflow-x: hidden;
  background-color: white;
}
