@import url(https://fonts.googleapis.com/css?family=Montserrat:500);
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Set default font family */
body {
  font-family: "Mulish", sans-serif;
}

/* Set default styles for headings */
h1,
h5 {
  margin-left: 10px;
}

/* Offer heading styles */
.offer-heading {
  text-align: center;
  padding: 15px;
  font-size: 24px;
  font-weight: bold;
  color: red;
  /* Additional text shadow */
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.4),
    0 0 15px rgba(0, 0, 0, 0.6), 0 0 20px rgba(0, 0, 0, 0.8),
    0 0 25px rgba(0, 0, 0, 1);
}

/* Special offers container */
.special-offers {
  border: 5px solid;
  border-radius: 25px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #f2f2f2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  box-sizing: border-box;
}

/* Offer list */
.offer-list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

/* Individual offer item */
.offer-item {
  font-size: 16px;
  background-color: #ff8002;
  padding: 2px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Offer validity paragraph */
.offer-validity {
  margin-top: 20px;
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
  color: red;
}
.about__home {
  background-color: white;
}
.about__home__title {
  padding-top: 20px;
  margin-left: 10px;
}
/* Home text */
.home-text {
  display: flex;
  gap: 10px;

  padding: 0;
  font-size: 14px;
  margin-right: 20px;
  justify-content: center;
}
.home-text__col__text {
  margin-left: 10px;

  border: 2px solid gainsboro;
  padding: 20px;
  height: 256px;
  box-shadow: 1px 1px 1px black;
  background-color: var(--site-color);
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.home__wrapper {
  height: 70vh;
  /*margin-bottom: 20%;*/
}
.home-header-icon-container {
  display: flex;
  align-items: center;

  margin: var(--padding-small) 0 var(--padding-medium) var(--padding-small);
}
.home-header-icon {
  display: flex;
  align-items: center;
}
.about-ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-bottom: 10px;
}

/* Adjust margin for home text paragraphs */

/* Image banner */
.image-banner {
  width: 100%;
  border-radius: 25px;
  /*max-height: 500px; */
}

/* Image wrapper */
.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px;
  position: relative;
}

/* Carousel wrapper */
.carousel-wrapper {
  margin-top: 20px;
  border-radius: 25px;
}

/* Company title */

/* Responsive adjustments */
@media (max-width: 480px) {
  /* Adjust image banner height for phones */
  .image-banner {
    height: 300px;
  }
  /* Further reduce font size for smaller screens */
  .company-title {
    padding-top: 10%;
    font-size: 26px;
    padding-left: 15px;
  }
}
@media (max-width: 580px) {
  /* Adjust image banner height for phones */
  .image-banner {
    height: 300px;
  }
  /* Further reduce font size for smaller screens */
  .company-title {
    padding-top: 10%;
    font-size: 26px;
    padding-left: 15px;
  }
}
@media (max-width: 895px) {
  /* Adjust image banner height for phones */

  .home-text {
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    align-content: center;
  }
  .home-text__col__text {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
  }
}
@media (max-width: 1195px) {
  /* Adjust image banner height for phones */

  .home-text__col__text {
    height: 140px;

    min-height: 140px;
    max-height: 140px;
  }
}

@media (max-width: 1050px) {
  /* Adjust image banner height for phones */

  .home-text__col__text {
    height: 200px;

    min-height: 200px;
    max-height: 200px;
  }
}
/* Banner */
.banner {
  background-color: #3498db;
  color: #fff;
  padding: 50px 0;
  border-radius: 25px;
  position: relative;
  z-index: 0;
}

/* Banner text */
.banner h1 {
  font-size: 36px;
  margin-top: 20px;
}

.banner p {
  font-size: 18px;
  margin-bottom: 20px;
}
.email-link {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none; /* Remove underline from the email link */
  width: 100%;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .email-link {
    /*width: 50%; /* Adjust the width for small screens */
  }
}
.email-link:hover {
  text-decoration: underline; /* Underline the email link on hover */
}
.box-icon {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  cursor: pointer;
}
.home__box__icon {
  text-align: "center";
  position: "absolute";
  right: "2%";
  top: "50%";
  cursor: pointer;
}
.home__tooltip__content__box {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  align-content: center;
  align-self: center;
}
.responsive-box {
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-in {
  animation: slideIn 1.5s ease forwards;
}
