body {
  overflow-x: hidden;
  background: linear-gradient(-45deg, #6452ee, #3cd9e7, #23a6d5, #23d5ab);
  background-size: 800% 800%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  display: block;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.content {
  /*border-radius: 39px;*/
  box-shadow: 0 4px 8px 0 rgb(4, 4, 4), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  min-height: 100vh;
  overflow-x: hidden;
}
.content h2 {
  padding: 0;
  margin: 0;
}
h1 {
  font-family: "Nobile", sans-serif;
  font-weight: bolder;
}
h6 {
  font-family: "Nobile", sans-serif;
  font-size: 1rem;
  font-weight: 600;
}
p {
  margin-left: 10px;
  font-family: "Nobile", sans-serif;
  font-size: 1rem;
  font-weight: 600;
}
li {
  /*margin-left: 10px; */
}
.container {
  display: flex;
}
.center-h {
  margin-left: 10px;
  text-align: start;
  margin: 10px;
}
@media only screen and (max-width: 375px) {
  .table {
    font-size: 10px;
    padding: 0;
    margin: 0;
  }
}

a {
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  font-weight: 900;
  color: black;
}

.nav-wid {
  height: 100%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgb(4, 4, 4), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

nav {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.centerText {
  text-align: center;
}
.margin-auto {
  padding-right: 15px;
  margin: auto auto;
}

.padding-zero {
  padding: 0;
}
.red {
  color: red;
}
.bronze {
  color: rgb(205, 127, 50);
}
.silver {
  color: rgb(192, 192, 192);
}
.gold {
  color: rgb(255, 215, 0);
}
.green {
  color: green;
}
.divider {
  border-top: 1px solid #ccc;
}
.center-li {
  margin-left: 10px;
}
