.about_start_text {
  margin-left: 10px;
}
ul {
  padding: 0;
}
li {
  display: inline-block;
}

.card-col {
  margin-right: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
li {
  font-weight: bold;
}
.img-responsive {
  width: 38vh;
}
.rounded-circle {
  width: 25%;
  height: 25%;
  border: 3px solid black;
}
.about_header_icon {
  display: flex;
  align-items: center;
  padding: var(--padding-medium) 0 var(--padding-medium) var(--padding-small);
  margin: var(--padding-small) 0 var(--padding-medium) var(--padding-small);
}
.bold-header {
  font-weight: bold;
  text-align: flex-start;
  margin-left: var(--padding-small);
}

.map iframe {
  width: 100%;
  height: 400px;
}
