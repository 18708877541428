.search-container {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.search-input {
  padding: 10px 40px; /* Adjust padding as needed */
  border: 1px solid #ccc; /* Add border color */
  border-radius: 5px; /* Add border radius */
  font-size: 16px; /* Adjust font size */
  width: 100%; /* Adjust width */
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  right: 0;
  margin-right: 10px;
}
