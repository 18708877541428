.flex-container {
  display: flex;
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-title {
  padding: var(--padding-medium) 0 var(--padding-medium) var(--padding-small);
  margin: var(--padding-small) 0 var(--padding-medium) var(--padding-small);
}
.sub-title {
  padding: var(--padding-small) 0 var(--padding-small) var(--padding-small);
  margin: var(--padding-small) 0 var(--padding-small) var(--padding-small);
}
.container {
  padding: var(--padding-small);
  margin: var(--padding-small);
}
.company-title {
  padding: var(--padding-medium) 0 var(--padding-medium) var(--padding-small);
  margin: var(--padding-small) 0 var(--padding-medium) var(--padding-small);
}
.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
}
